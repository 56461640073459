import allMarkers from "./Content1.js";
// NOTE THIS FUNCTION BREAKS THE CODE ATM
// function filterMarkers(category){
//     for (i=0; i < allMarkers.length; i++){
//         if (allMarkers[i][0] != category){
//             hide(i);
//         }
//         else{
//             show(i);
//         }
//     }
// }
function hide(index) {
    let markers = document.getElementsByClassName("marker");
    markers[index].style.visibility = "hidden";
}

function show(index) {
    let markers = document.getElementsByClassName("marker");
    markers[index].style.visibility = "visible";
}

function showAll() {
    let markers = document.getElementsByClassName("marker");
    for (let i = 0; i < markers.length; i++) {
        markers[i].style.visibility = "visible";
    }
}

export {hide, show, showAll};