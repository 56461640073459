import React, { Component } from 'react';
import './Header.css';
import UploadButton from './UploadButton.js';
import ModalButton from './ModalButton.js';
import FormModal from './FormModal.js';

function Header() {
    return (
        <header>
            <nav>
                <h1>Living Atlas</h1>
                <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">About</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>
            </nav>
            <div className="search-bar">
                <input type="text" placeholder="Search..." />
                <button>Search</button>
                <div className="nav-buttons">
                    <button>Filter 1</button>
                    <button>Filter 2</button>
                    <button>Filter 3</button>
                    <FormModal className="form-modal-button" />


                </div>

            </div>



        </header>
    );
}


export default Header;
