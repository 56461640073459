import React, { useState } from 'react';
import './Card.css';
import Modal from 'react-modal';



function Card(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleLearnMore = () => {
        // alert(`Clicked Learn More for ${props.title}`);
        setIsModalOpen(true);
    };

    // const handleGetInfo = () => {
    //     fetch(`http://localhost:8000/mpc/rivers/${props.title}`)
    //       .then(response => response.json())
    //       .then(data => {
    //         alert(`Get Info for Card ${props.title}: ${JSON.stringify(data)}`);
    //       })
    //       .catch(error => console.error(error));
    // };

    const handleDownload = () => {

        const url = window.URL.createObjectURL(new Blob([props.formPdf]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'example.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };


    return (
        <div className="card">
            {/* <img src={props.image} alt={props.title} style={{ width: '200px', height: '200px' }} /> */}
            <h2>{props.formData.title}</h2>
            <p>{props.formData.description}</p>
            <button onClick={handleLearnMore}>Learn More</button>
            {/* <button onClick={handleGetInfo}>Get Info</button> */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                className="Modal">
                <h2>{props.formData.title}</h2>
                <p>Name: {props.formData.username}</p>
                <p>Email: {props.formData.email}</p>
                <p>Funding: {props.formData.funding}</p>
                <p>Organization: {props.formData.org}</p>
                <p>Title: {props.formData.title}</p>
                <p>Link: {props.formData.link}</p>
                <p>Description: {props.formData.description}</p>
                <p>Tags: {props.formData.tags}</p>
                <p>Latitude: {props.formData.latitude}</p>
                <p>Longitude: {props.formData.longitude}</p>
                {props.formPdf && (
                    <div>
                        <button onClick={handleDownload}>Download PDF</button>
                    </div>
                )}


                <button onClick={() => setIsModalOpen(false)}>Close</button>
            </Modal>
        </div>
    );
}

export default Card;
