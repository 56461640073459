import React from 'react';
import Content1 from './Content1';
import Content2 from './Content2';
import './Main.css';


function Main() {
    return (
        <main>
            <Content1 />
            <Content2 />
        </main>
    );
}

export default Main;
