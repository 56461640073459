import React, { useState } from 'react';
import Modal from 'react-modal';
import './FormModal.css';
import axios from 'axios';

const FormModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);




    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    //file: UploadFile = File(None)
    //async def submit_form(name: str = Form(None), email: str = Form(None), funding: str= Form(None), organization: str= Form(None), title: str= Form(None), link: str= Form(None), description: str= Form(None), tags: UploadFile = File(None),latitude: str= Form(None),longtitude: str= Form(None),):


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData2 = new FormData();
        formData2.append('name', formData.name);
        formData2.append('email', formData.email);
        formData2.append('funding', formData.funding);
        formData2.append('org', formData.org);
        formData2.append('title', formData.title);
        formData2.append('link', formData.link);
        formData2.append('description', formData.description);
        formData2.append('tags', formData.tags);
        formData2.append('latitude', formData.latitude);
        formData2.append('lon', formData.lon);
        if (selectedFile) {
            formData2.append('file', selectedFile);
        }



        axios.post('http://localhost:8000/upload', formData2, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });



    };


    const handleButtonClick = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div>
            <button className="open-form-button" onClick={handleButtonClick}>Upload</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="form-modal"
                overlayClassName="form-modal-overlay"
                ariaHideApp={false}
            >
                <h2>Upload Document</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="funding">Funding:</label>
                    <input
                        type="text"
                        id="funding"
                        name="funding"
                        value={formData.funding}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="org">Organization:</label>
                    <input
                        type="text"
                        id="org"
                        name="org"
                        value={formData.org}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="link">Link:</label>
                    <input
                        type="text"
                        id="link"
                        name="link"
                        value={formData.link}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                    <input type="file" onChange={handleFileInput} />
                    <label htmlFor="tags">Tags:</label>
                    <input
                        type="text"
                        id="tags"
                        name="tags"
                        value={formData.tags}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="latitude">latitude:</label>
                    <input
                        type="text"
                        id="latitude"
                        name="latitude"
                        value={formData.latitude}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="lon">longitude:</label>
                    <input
                        type="text"
                        id="lon"
                        name="lon"
                        value={formData.lon}
                        onChange={handleInputChange}
                        required
                    />



                    <button type="submit" onClick={handleSubmit} >Submit</button>
                    <button type="cancel" onClick={closeModal} >Cancel</button>
                </form>
            </Modal>
        </div>
    );
};

export default FormModal;