import React from 'react';
import './Content2.css';
import Card from './Card.js';
import { useEffect, useState } from 'react';
import axios from 'axios';

function Content2() {



    const [cards, setCards] = useState([]);


    useEffect(() => {
        axios.get('http://localhost:8000/all2')
            .then(response => {
                console.error(response.data.data);
                console.error();
                setCards(response.data.data);

            })
            .catch(error => {
                console.error(error);
            });
    }, []);







    return (
        <section id="content-2">
            <h1>Content Area 2</h1>
            <p>
                Here, you can see the results of your search displayed in a grid format. Each card represents a unique data point. You can sort the results by clicking on the filter or using the search.
            </p>

            <div className="card-container">

                {cards.map(card => (
                    <Card formData={card} />
                ))}
            </div>

            {/* <div>
                <h3>JSON Response:</h3>
                <pre>{JSON.stringify(cards, null, 2)}</pre>
            </div> */}
        </section>
    );
}

export default Content2;
